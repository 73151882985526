const usernameRules = [
  v => !!v || 'Field is required',
  v => v.length >= 3 || 'Username must be at least 3 characters',
  v => v.length < 150 || 'Username must be less than 150 characters',
  v => /^[\w.+-]+$/.test(v) || 'Username may contain only letters, numbers, and ./+/-/_ characters',
]

const passwordRules = [
  v => !!v || 'Field is required',
  v => v.length >= 6 || 'Password must be a minimum of 6 characters',
]

const firstNameRules = [
  v => v.length < 30 || 'First name must be less than 30 characters',
]

const lastNameRules = [
  v => v.length < 150 || 'Last name must be less than 150 characters',
]

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const emailRules = [
  v => !!v || 'Field is required',
  v => emailRegex.test(v) || 'Enter a valid email'

]

const messageContentRules = [
  v => !!v || 'Field is required',
  v => v && v.length <= 4000 || 'Please restrain yourself to 4,000 characters',
]

const messageSubjectRules = [
  v => !!v || 'Field is required',
  v => v && v.length <= 150 || 'Please restrain yourself to 150 characters',
]

const teamNameRules = [
  v => !!v || 'Field is required',
  v => v && v.length >= 3 || 'Team name must be at least 3 characters',
  v => v && v.length < 250 || 'Team name must be less than 250 characters',
]

const eventDescriptionRules = [
  v => (!v || v.length <= 4000) || 'Please restrain yourself to 4,000 characters',
]

export {
  emailRules,
  eventDescriptionRules,
  firstNameRules,
  lastNameRules,
  messageContentRules,
  messageSubjectRules,
  passwordRules,
  teamNameRules,
  usernameRules,
}
