<template>
  <v-card>
    <v-card-title>
      Shop
    </v-card-title>
    <v-list>
      <v-list-item two-line>
        <v-list-item-content>
          <v-list-item-title v-text="profile.points_available" />
          <v-list-item-subtitle>Points Available</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <ShopPointsHelp />
        </v-list-item-action>
      </v-list-item>
      <v-card-actions>
        <v-btn :to="{ name: 'Shop' }">
          Shop Now
        </v-btn>
      </v-card-actions>
    </v-list>
  </v-card>
</template>

<script>
import { mapState } from 'vuex'
import ShopPointsHelp from '@/components/ShopPointsHelp.vue'

export default {
  components: {
    ShopPointsHelp,
  },
  computed: {
    ...mapState({
      profile: state => state.auth.profile,
    }),
  },
}
</script>
