<template>
  <span>
    <v-btn
      icon
      :x-small="small"
      @click="dialog = true"
    >
      <v-icon>
        $help
      </v-icon>
    </v-btn>
    <v-dialog
      v-model="dialog"
      max-width="400"
      scrollable
    >
      <v-card>
        <v-card-title>
          Your Available Points Unlocks Restricted Items for Purchase
        </v-card-title>
        <v-card-text>
          <p>As you shop, some items will require you to turn in points to complete the purchase. Doing so will not affect your standing on the leader boards or the points displayed on your public profile.</p>
          <p>For example, assume you have earned <strong>1,000</strong> points and you purchase an item that requires you to turn in <strong>300</strong> points. After the purchase you will still appear with <strong>1,000</strong> points on the global leader board, but you will only have <strong>700</strong> points available for other purchases. If you then earn an additional <strong>200</strong> points, you will appear on the global leader board with <strong>1,200</strong> points and you will have <strong>900</strong> points available.</p>
        </v-card-text>
        <v-card-actions>
          <v-btn
            text
            @click="dialog = false"
          >
            Got It
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
export default {
  props: {
    small: {
      type: Boolean,
      default: false,
    },
  },
  data: function() {
    return {
      dialog: false,
    }
  },
}
</script>
