<template>
  <div>
    <v-alert
      v-if="!$online"
      type="error"
    >
      An internet connection is required to modify your settings.
    </v-alert>
    <Loading
      v-else-if="submitting"
      :loading="submitting"
      message="Submitting"
    />
    <div v-else>
      <v-row v-if="success || errorMessages">
        <v-col class="pb-0">
          <v-alert
            v-if="success"
            type="success"
          >
            Settings updated successfully. Note that it may take a minute for your changes to take effect.
          </v-alert>
          <v-alert
            v-for="error in errorMessages"
            :key="error"
            type="error"
          >
            {{ error }}
          </v-alert>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          cols="12"
          lg="6"
        >
          <v-form
            ref="form"
            @submit.prevent="submitSettings"
          >
            <v-card>
              <v-card-title>
                Settings
              </v-card-title>
              <v-list subheader>
                <v-list-item>
                  <v-text-field
                    v-model="username"
                    :disabled="submitting"
                    :rules="usernameRules"
                    type="text"
                    label="Username"
                  />
                </v-list-item>
                <v-list-item>
                  <v-text-field
                    v-model="firstName"
                    :disabled="submitting"
                    :rules="firstNameRules"
                    type="text"
                    label="First Name"
                  />
                </v-list-item>
                <v-list-item>
                  <v-text-field
                    v-model="lastName"
                    :disabled="submitting"
                    :rules="lastNameRules"
                    type="text"
                    label="Last Name"
                  />
                </v-list-item>
                <v-list-item>
                  <v-select
                    v-model="homeRegionSelect"
                    :disabled="submitting"
                    :items="regionList"
                    item-text="name"
                    item-value="id"
                    label="Home Region"
                  />
                  <v-btn
                    icon
                    @click="selectCurrentRegion"
                  >
                    <v-icon>$location</v-icon>
                  </v-btn>
                </v-list-item>
                <v-list-item>
                  <div>
                    <div>
                      <label
                        for="avatar"
                        class="input-label"
                        :class="{'label-error':(avatarError)}"
                      >
                        Avatar
                      </label>
                    </div>
                    <div>
                      <input
                        id="avatar"
                        class="avatar-input"
                        type="file"
                        name="html-image"
                        accept="image/heic,image/heif,image/jpeg,image/png,.heic,.heif,.jpeg,.jpg,.png"
                        :disabled="submitting"
                        :class="{'input-error':(avatarError)}"
                        @change="imageInput($event)"
                      >
                    </div>
                    <div
                      v-if="avatarError"
                      class="hint error-message"
                    >
                      {{ avatarError }}
                    </div>
                    <div
                      v-else
                      class="hint"
                    >
                      Avatars should be JPG, PNG, HEIC, or HEIF and no larger than 13MB. We recommend a 512x512 image for best results.
                    </div>
                  </div>
                </v-list-item>
              </v-list>
              <v-list subheader>
                <v-subheader>
                  Sharing
                </v-subheader>
                <v-list-item>
                  <v-switch
                    v-model="shareFullName"
                    :disabled="submitting"
                    label="Share Name"
                    hint="When enabled, other users will be see your full name on your profile."
                    persistent-hint
                  />
                </v-list-item>
                <v-list-item>
                  <v-switch
                    v-model="shareEvidence"
                    :disabled="submitting"
                    label="Share Evidence"
                    hint="When enabled, other users will see your approved evidence images on your profile."
                    persistent-hint
                  />
                </v-list-item>
                <v-list-item>
                  <v-switch
                    v-model="shareRecruits"
                    :disabled="submitting"
                    label="Share Recruits"
                    hint="When enabled, other users will see a list of the users you have referred on your profile."
                    persistent-hint
                  />
                </v-list-item>
              </v-list>
              <v-card-actions>
                <v-row dense>
                  <v-col
                    cols="12"
                    sm="auto"
                  >
                    <v-btn
                      :disabled="submitting || avatarError"
                      type="submit"
                      color="primary"
                      width="100%"
                    >
                      Save
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="auto"
                  >
                    <v-btn
                      :to="{name: 'PasswordChange'}"
                      width="100%"
                    >
                      Change Password
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="auto"
                  >
                    <v-btn
                      :to="{name: 'EmailChange'}"
                      width="100%"
                    >
                      Change Email
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="12"
                    sm="auto"
                  >
                    <v-btn
                      :to="{name: 'ProfileDelete'}"
                      width="100%"
                    >
                      Delete My Account
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
          </v-form>
        </v-col>
        <v-col
          cols="12"
          lg="6"
        >
          <v-card>
            <v-card-title>
              Need Help?
            </v-card-title>
            <v-card-text>
              <!-- eslint-disable vue/singleline-html-element-content-newline -->
              <p>
                Explore our <router-link :to="{ path: '/faq/' }">frequently asked questions</router-link>.
              </p>
              <p>
                For a refresher, read our <router-link :to="{ path: '/start-questing/' }">getting started guide</router-link>.
              </p>
              <p>
                Want to get in touch? Email us at <a href="mailto:support@tripleaughtdesign.com">support@tripleaughtdesign.com</a>.
              </p>
              <!--eslint-enable-->
            </v-card-text>
          </v-card>
          <ShoppingInfoTile
            v-if="profile.allow_shop"
            class="mt-6"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import { allianceAPI } from '@/api'
import { mapState } from 'vuex'
import { checkImageValue, imageTimeout } from '@/utils/images'
import { parseResponseErrors } from '@/utils/errors'
import { usernameRules, firstNameRules, lastNameRules } from '@/utils/rules'
import Loading from '@/components/Loading.vue'
import ShoppingInfoTile from '@/components/ShoppingInfoTile.vue'

export default {
  components: {
    Loading,
    ShoppingInfoTile,
  },
  data() {
    return {
      submitting: false,
      success: null,
      errorMessages: null,
      homeRegionSelect: null,
      firstName: '',
      lastName: '',
      shareFullName: null,
      shareEvidence: null,
      shareRecruits: null,
      username: '',
      avatar: null,
      avatarError: false,
      avatarChanged: false,
      usernameRules: usernameRules,
      firstNameRules: firstNameRules,
      lastNameRules: lastNameRules,
    }
  },
  computed: {
    ...mapState({
      regionList: state => state.regions.all,
      profile: state => state.auth.profile,
      userCoordinates: state => state.userlocation.coordinates,
    }),
  },
  created: function () {
    if (this.$online) {
      this.$store.dispatch('auth/getProfile')
    }
    this.init()
  },
  methods: {
    init: function () {
      this.firstName = this.profile.first_name
      this.lastName = this.profile.last_name
      this.username = this.profile.user
      this.shareFullName = this.profile.share_full_name
      this.shareEvidence = this.profile.share_evidence
      this.shareRecruits = this.profile.share_recruits
      if (this.profile.home_region) {
        this.homeRegionSelect = this.profile.home_region.id
      }
    },
    imageInput: function(event) {
      let valid = checkImageValue(event.target.files[0])
      if (valid === true) {
        this.avatarError = false
        this.avatar = event.target.files[0]
      } else {
        this.avatarError = valid
      }
    },
    submitSettings: function () {
      if (!this.$refs.form.validate()) {
        return
      }
      this.submitting = true
      this.success = null
      this.errorMessages = null
      const data = new FormData()
      data.append('username', this.username)
      data.append('first_name', this.firstName)
      data.append('last_name', this.lastName)
      data.append('share_full_name', this.shareFullName)
      data.append('share_evidence', this.shareEvidence)
      data.append('share_recruits', this.shareRecruits)
      if (this.homeRegionSelect) {
        data.append('home_region', this.homeRegionSelect)
      }
      if (this.avatar) {
        data.append('avatar', this.avatar)
      }
      return allianceAPI.put(
        '/alliance/profile/me/',
        data,
        { timeout: imageTimeout },
      )
        .then((response) => {
          console.log('success!')
          console.log(response)
          this.success = true
          // Get the new profile.
          this.$store.dispatch('auth/getProfile')
          // Reset the avatar field.
          if (this.avatar) {
            this.avatarChanged = true
            this.avatar = null
          }
        })
        .catch((error) => {
          this.errorMessages = parseResponseErrors(error)
          this.success = false
        })
        .finally(() => this.submitting = false)
    },
    async selectCurrentRegion () {
      await this.$store.dispatch('userlocation/get')
      if (this.userCoordinates) {
        this.$store.dispatch('regions/calcDistance')
        this.homeRegionSelect = this.$store.getters['regions/getClosest'].id
      }
    },
  },
}
</script>
