const maxFileSize = 13 * 1024 * 1024  // 13MB
const imageTimeout = 60000
const allowed_types = [
  "image/heic",
  "image/heif",
  "image/jpeg",
  "image/png",
]

function checkImageValue (value) {
  if (allowed_types.indexOf(value.type) < 0 ) {
    return `The selected file does not appear to be a supported image. Supported file types are: ${allowed_types.join(', ')}`
  }
  if (value.size > maxFileSize) {
    return 'Image must be 13MB or less.'
  }
  return true
}

export {
  checkImageValue,
  imageTimeout,
}
